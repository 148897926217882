@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fix zoom in on input focus iOS */
/* https://www.expiredqueues.com/css/stop-zoom-in-on-input-focus-on-mobile-devices/ */
@media screen and (max-width: 768px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}
